import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-boldest m-0" }
const _hoisted_5 = { class: "card-body p-9" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-md-6" }
const _hoisted_8 = { class: "form-group d-flex flex-wrap" }
const _hoisted_9 = { class: "col-form-label required fw-bold fs-6" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-md-6" }
const _hoisted_12 = { class: "form-group d-flex flex-wrap" }
const _hoisted_13 = { class: "col-form-label required fw-bold fs-6" }
const _hoisted_14 = { class: "col-md-6" }
const _hoisted_15 = { class: "form-group d-flex flex-wrap" }
const _hoisted_16 = { class: "col-form-label required fw-bold fs-6" }
const _hoisted_17 = { class: "col-md-6" }
const _hoisted_18 = { class: "form-group d-flex flex-wrap" }
const _hoisted_19 = { class: "col-form-label fw-bold fs-6" }
const _hoisted_20 = { class: "col-md-6" }
const _hoisted_21 = { class: "form-group d-flex flex-wrap" }
const _hoisted_22 = { class: "col-form-label fs-6 fw-bold mb-2" }
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "form-group d-flex flex-wrap" }
const _hoisted_25 = { class: "col-form-label fs-6 fw-bold mb-2" }
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "form-group d-flex flex-wrap" }
const _hoisted_28 = { class: "col-form-label fw-bold fs-6" }
const _hoisted_29 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_30 = {
  type: "submit",
  ref: "submitButton",
  class: "btn btn-primary"
}
const _hoisted_31 = { class: "indicator-label" }
const _hoisted_32 = { class: "card mb-5 mb-xl-10" }
const _hoisted_33 = { class: "card-header" }
const _hoisted_34 = { class: "card-title m-0" }
const _hoisted_35 = { class: "fw-boldest m-0" }
const _hoisted_36 = { class: "card-body p-9" }
const _hoisted_37 = { class: "row" }
const _hoisted_38 = { class: "col-md-6" }
const _hoisted_39 = { class: "form-group d-flex flex-wrap" }
const _hoisted_40 = { class: "col-form-label fs-6 fw-bold mb-2" }
const _hoisted_41 = { class: "col-md-6" }
const _hoisted_42 = { class: "form-group d-flex flex-wrap" }
const _hoisted_43 = { class: "col-form-label fs-6 fw-bold mb-2" }
const _hoisted_44 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_45 = {
  type: "submit",
  ref: "submitButton",
  class: "btn btn-primary"
}
const _hoisted_46 = { class: "indicator-label" }
const _hoisted_47 = { class: "card mb-5 mb-xl-10" }
const _hoisted_48 = { class: "card-header" }
const _hoisted_49 = { class: "card-title m-0" }
const _hoisted_50 = { class: "fw-boldest m-0" }
const _hoisted_51 = { class: "card-body p-9" }
const _hoisted_52 = { class: "row" }
const _hoisted_53 = { class: "col-md-6" }
const _hoisted_54 = { class: "form-group d-flex flex-wrap" }
const _hoisted_55 = { class: "col-form-label fw-bold fs-6" }
const _hoisted_56 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_57 = {
  type: "submit",
  ref: "submitButton",
  class: "btn btn-primary"
}
const _hoisted_58 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_form, {
    onSubmit: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
    model: _ctx.formData,
    rules: _ctx.rules,
    ref: "formRef"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.t("permissions.information")), 1)
          ])
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.t("permissions.systemStatus")), 1),
                _createVNode(_component_el_form_item, { prop: "status" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_switch, {
                      modelValue: _ctx.formData.status,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.status) = $event)),
                      "active-value": 10,
                      "inactive-value": 25,
                      placeholder: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.t("permissions.menuID")), 1),
                _createVNode(_component_el_form_item, { prop: "id" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.formData.id,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.id) = $event)),
                      type: "text",
                      placeholder: "",
                      disabled: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.t("permissions.menuName")), 1),
                _createVNode(_component_el_form_item, { prop: "name" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.formData.name,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.name) = $event)),
                      type: "text",
                      placeholder: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.t("permissions.parentID")), 1),
                _createVNode(_component_el_form_item, { prop: "parent_id" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_cascader, {
                      options: _ctx.cascaderData,
                      "show-all-levels": false,
                      props: _ctx.propData,
                      clearable: "",
                      filterable: "",
                      lazy: "",
                      modelValue: _ctx.formData.parent_id,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.parent_id) = $event)),
                      onChange: _ctx.parentChange
                    }, null, 8, ["options", "props", "modelValue", "onChange"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.t("permissions.sorting")), 1),
                _createVNode(_component_el_form_item, { prop: "sorting" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.formData.sorting,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.sorting) = $event)),
                      type: "number"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.t("permissions.routeUrl")), 1),
              _createVNode(_component_el_form_item, { prop: "route_uri" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.formData.route_uri,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.route_uri) = $event)),
                    type: "text"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.t("permissions.overallNotes")), 1),
              _createVNode(_component_el_form_item, { prop: "overall_notes" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.formData.overall_notes,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.overall_notes) = $event)),
                    type: "textarea"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ])
          ])
        ])), [
          [_directive_loading, _ctx.loading]
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createVNode(_component_router_link, {
            to: '/permissions/menu',
            class: "btn btn-secondary align-self-center me-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("common.backToList")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("button", _hoisted_30, [
            _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t("common.saveChanges")), 1)
          ], 512)
        ])
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("h3", _hoisted_35, _toDisplayString(_ctx.t("permissions.style")), 1)
          ])
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_36, [
          _createElementVNode("div", _hoisted_37, [
            _createElementVNode("div", _hoisted_38, [
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.t("permissions.iconFontName")), 1),
                _createVNode(_component_el_form_item, { prop: "icon_font_name" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.formData.icon_font_name,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.icon_font_name) = $event)),
                      type: "text"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_41, [
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.t("permissions.iconSvgPath")), 1),
                _createVNode(_component_el_form_item, { prop: "icon_svg_path" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.formData.icon_svg_path,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.icon_svg_path) = $event)),
                      type: "text"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ])), [
          [_directive_loading, _ctx.loading]
        ]),
        _createElementVNode("div", _hoisted_44, [
          _createVNode(_component_router_link, {
            to: '/permissions/menu',
            class: "btn btn-secondary align-self-center me-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("common.backToList")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("button", _hoisted_45, [
            _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.t("common.saveChanges")), 1)
          ], 512)
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_47, [
        _createElementVNode("div", _hoisted_48, [
          _createElementVNode("div", _hoisted_49, [
            _createElementVNode("h3", _hoisted_50, _toDisplayString(_ctx.t("permissions.applicationConfig")), 1)
          ])
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_51, [
          _createElementVNode("div", _hoisted_52, [
            _createElementVNode("div", _hoisted_53, [
              _createElementVNode("div", _hoisted_54, [
                _createElementVNode("label", _hoisted_55, _toDisplayString(_ctx.t("permissions.appId")), 1),
                _createVNode(_component_el_form_item, { prop: "app_id" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.formData.app_id,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formData.app_id) = $event)),
                      clearable: "",
                      placeholder: "Please select",
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectData, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.value,
                            label: item.label,
                            value: item.value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ])), [
          [_directive_loading, _ctx.loading]
        ]),
        _createElementVNode("div", _hoisted_56, [
          _createVNode(_component_router_link, {
            to: '/permissions/menu',
            class: "btn btn-secondary align-self-center me-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("common.backToList")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("button", _hoisted_57, [
            _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.t("common.saveChanges")), 1)
          ], 512)
        ])
      ], 512), [
        [_vShow, !_ctx.formData.parent_id || _ctx.formData.parent_id === '0']
      ])
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}