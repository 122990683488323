
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiMenu } from "@/core/api";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

interface Menu {
  id: string;
  name: string;
  parent_id: string;
  children: Array<any>;
}

interface propData {
  checkStrictly: boolean;
  value: string;
  label: string;
  children: string;
  lazy: boolean;
  lazyLoad: any;
}

export default defineComponent({
  name: "menu-detail",
  components: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const selectData = ref([]);
    const cascaderData = ref<Array<Menu>>([]);

    const propData = ref<propData>({
      checkStrictly: true,
      value: "id",
      label: "name",
      children: "children",
      lazy: true,
      lazyLoad(node, resolve) {
        resolve(node.children);
      },
    });

    const formData = ref({
      id: "",
      status: 10,
      name: "",
      app_id: "",
      parent_id: "",
      route_uri: "",
      icon_font_name: "",
      icon_svg_path: "",
      sorting: "",
      overall_notes: "",
    });

    const rules = ref({
      status: [
        {
          required: true,
          message: t("common.status") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          message: t("permissions.menuName") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      parent_id: [
        {
          required: false,
          message: t("permissions.parentID") + t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    watch(
      formData,
      (nV, oV) => {
        if (nV?.parent_id !== "" && nV?.parent_id !== "0") {
          nV.app_id = "";
        }
      },
      { deep: true }
    );

    const getAppTypeOptions = (status?: number) => {
      ApiMenu.getAppSourceData({ status })
        .then(({ data }) => {
          if (data.code == 0) {
            selectData.value = data.data;
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getSelectList = (id?: string) => {
      ApiMenu.getMenuSourceData({ id })
        .then(({ data }) => {
          if (data.code == 0) {
            cascaderData.value.splice(
              0,
              cascaderData.value.length,
              ...data.data
            );
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getDetailData = () => {
      loading.value = true;
      ApiMenu.getMenuInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiMenu.updateMenu(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteMenu = () => {
      Swal.fire({
        text: t("common.deactivateMenuTips"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiMenu.deleteMenu({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: t("common.menuDeactivatedTips"),
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "menu" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const parentChange = (value) => {
      if (value) {
        formData.value.parent_id = value[value.length - 1];
      } else {
        formData.value.parent_id = "";
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("permissions.information"), [
        { name: t("permissions.permissions"), path: "" },
        { name: t("permissions.menuManagement"), path: "/permissions/menu" },
      ]);
      getDetailData();
      getAppTypeOptions();
      getSelectList();
    });

    return {
      t,
      loading,
      formRef,
      selectData,
      cascaderData,
      propData,
      formData,
      rules,
      submit,
      deleteMenu,
      parentChange,
    };
  },
});
